<template>
  <!-- 搜索选择城市页面下方有个历史记录 -->
  <div class="history">
    <div v-show="list.length>0">
      <van-icon name="search" style="top: 2px" />
      <span
        class="list-text"
        v-for="(item,i) in list"
        :key="i"
        @click="$emit('setCity',item)"
      >{{item.from}}-{{item.to}}</span>
      <span class="list-text blue" @click="reset" v-if="config_controlLevel<20">清除历史</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CityRE } from "@/common/config.js";
export default {
  name: "History",
  props: {
    historyKey: {
      default: () => "trainHistory"
    }
  },
  computed: {
    ...mapGetters(["travelInfo","config_controlLevel"]),
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    // 清空历史记录
    reset() {
      localStorage.setItem(this.historyKey, JSON.stringify([]));
      this.list = [];
    },
    // 追加
    append(data) {
      let f = true;
      this.list.forEach(element => {
        f = f && !(element.to == data.to && element.from == data.from);
      });
      if (f) {
        this.list.unshift({
          to: data.to,
          from: data.from
        });
        this.list.splice(20)
        localStorage.setItem(this.historyKey, JSON.stringify(this.list));
      }
    }
  },
  created() {
    if (!localStorage.getItem(this.historyKey)) this.reset();
    let temp = []
    switch (this.config_controlLevel) {
      case 0:
        // 如果是飞机，需要把“保定东”滤掉，其余均保留
        if(this.$route.name.toLowerCase().includes('aircraft')){
          temp=temp.filter(e=>{
            return !((e.to.search(CityRE)+1==e.to.length) || (e.from.search(CityRE)+1==e.from.length))
          })
        }
        temp = JSON.parse(localStorage.getItem(this.historyKey)) || [];
        break;
      case 10:
        temp = JSON.parse(localStorage.getItem(this.historyKey)) || []
        temp=temp.filter(e=>{
          // 如果是火车，需要把“保定东”保留下来，机票则需要过滤掉
          if(this.$route.name.toLowerCase().includes('train')){
            return this.travelInfo.routeCityEnd.includes(e.to.replace(CityRE, ''))&&this.travelInfo.routeCityEnd.includes(e.from.replace(CityRE, ''))
          }
          return this.travelInfo.routeCityEnd.includes(e.to)&&this.travelInfo.routeCityEnd.includes(e.from)
        })
        break;
      case 20:
        let array = this.travelInfo.routeCityEnd
        let len = array.length
        for (let i = 0; i < len; i++) {
          const fromCity = array[i];
          const toCity = array[(i+1)%len]
          temp.push({ to:toCity, from:fromCity })
        }
        break;
    }
    this.list = temp;
  }
};
</script>

<style lang="less" scoped>
.history {
  overflow: hidden;
  div {
    width: 95vw;
    margin: 0 auto;
    white-space: nowrap;
    overflow: scroll;
    .list-text {
      padding: 0.4rem 0.4rem 0.8rem;
      display: inline-block;
    }
  }
}
</style>